import React from 'react';

function AboutMe() {
  return (
    <div className="min-h-screen bg-gray-100 font-inter">
      {/* Navbar */}
      <nav className="fixed top-0 left-0 z-50 w-full p-4 font-bold text-white bg-blue-500 shadow-md">
        <div className="container flex items-center justify-between mx-auto">
          <h1 className="text-base text-left sm:text-lg md:text-xl lg:text-2xl">Who is Yash?</h1>
          <div className="flex space-x-4">
            <a href="/" className="text-white hover:text-gray-200">Home</a>
          </div>
        </div>
      </nav>

      {/* Main content */}
      <div className="flex flex-col items-center justify-center pt-24 pb-12">
        <div className="flex flex-col items-center justify-center w-full max-w-6xl p-6 bg-white rounded-lg shadow-lg lg:flex-row">
          
          {/* Left side: Photo */}
          <div className="flex-1 mb-6 lg:mb-0 lg:w-1/3">
            <img
              src="yash.jpg"  // Replace with your photo URL
              alt="Yash Phulfagar Jain"
              className="object-cover w-48 h-48 mx-auto rounded-lg sm:w-64 sm:h-64 lg:w-96 lg:h-96"  // Larger on desktop
            />
          </div>
          
          {/* Right side: About Content */}
          <div className="flex-1 px-6 text-center lg:w-2/3 lg:text-left">
            <h2 className="mb-4 text-2xl font-semibold">Hello, I'm Yash Phulfagar Jain</h2>
            <p className="mb-4 text-justify text-gray-700">
              I’m an upcoming Engineer who's playing around with tech and trying hands-on automation. Currently, I am pursuing a B.Tech in Computer Science at VIT, Chennai, and a B.Sc in Programming and Data Science at IIT, Madras. I'm driven by my love for technology and the desire to build tools that solve real-world problems.
            </p>
            <p className="mb-4 text-justify text-gray-700">
              My professional journey has been a mix of academia, internships, and personal projects, including work on platforms like MySmartPrice and OnSiteGo. I believe in lifelong learning and constantly seek opportunities to expand my skill set.
            </p>
            <p className="text-justify text-gray-700">
              Apart from work, I am involved in various community-driven initiatives and love connecting with like-minded people. Feel free to reach out to me at me@yashphulfagar.com if you want to collaborate or just have a conversation about technology, business, or life in general!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutMe;
