import React, { useEffect, useState, useCallback } from 'react';
import Papa from 'papaparse';
import { GoogleGenerativeAI } from "@google/generative-ai";
import birthdaysCSV from '../dec-birthdays.csv';
import tasksCSV from '../tasks.csv';
import Navbar from './Navbar';

const capitalizeName = (name) => {
  return name
    .trim()
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const BirthdayMessageGenerator = () => {
  const [quote, setQuote] = useState('');
  const [birthdays, setBirthdays] = useState([]);
  const [task, setTask] = useState('');
  const [tasks, setTasks] = useState([]);
  const apiKey = process.env.REACT_APP_API_KEY;

  const fetchRandomTask = useCallback(() => {
    if (tasks.length > 0) {
      const randomIndex = Math.floor(Math.random() * tasks.length);
      setTask(tasks[randomIndex].Task);
    }
  }, [tasks]);

  useEffect(() => {
    Papa.parse(tasksCSV, {
      download: true,
      header: true,
      complete: (results) => {
        setTasks(results.data);
      },
    });
  }, []);

  useEffect(() => {
    if (tasks.length > 0 && !task) {
      fetchRandomTask();
    }
  }, [tasks, task, fetchRandomTask]);

  const fetchTodaysBirthdays = useCallback(async () => {
    const today = new Date();
    const todayDate = today.getDate().toString().padStart(2, '0');
    const todayMonth = (today.getMonth() + 1).toString().padStart(2, '0');
    const formattedToday = `${todayDate}-${todayMonth}`;
  
    Papa.parse(birthdaysCSV, {
      download: true,
      header: true,
      complete: (results) => {
        const todayBirthdays = results.data
          .filter((row) => {
            const [day, month] = row.birthday.split('-');
            return `${day}-${month}` === formattedToday;
          })
          .map((row) => ({
            ...row,
            name: capitalizeName(row.name),
            phone: row.phone.trim(),
          }));
        setBirthdays(todayBirthdays);
      },
    });
  }, []);

  const fetchQuote = useCallback(async () => {
    try {
      const genAI = new GoogleGenerativeAI(apiKey);
      const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });
      const prompt = "give me an inspirational quote a new one that is not too long, always give me a new quote dont repeat any, generate new ones";
      const result = await model.generateContent(prompt);
      const quoteText = result.response.text();
      setQuote(quoteText);
    } catch (error) {
      console.error("Error fetching quote: ", error);
    }
  }, [apiKey]);

  useEffect(() => {
    fetchTodaysBirthdays();
    fetchQuote();
  }, [fetchTodaysBirthdays, fetchQuote]);

  const formatMessage = useCallback(() => {
    const today = new Date();
    const formattedDate = today.toLocaleDateString('en-IN', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });

    const birthdayLinks = birthdays
      .map(
        (person) =>
          `*${person.name}*\nhttps://wa.me/91${person.phone}\n`
      )
      .join('\n');

    const formattedMessage = `*JITO Youth Wing wishes its members a very Happy Birthday🥳*\n\n🗓️ ${formattedDate}\n\n${birthdayLinks}\nComplete the following task, post it on your stories or feed, tag @JITOChennaiYouth, and stand a chance to win exciting prizes:\n\n🚀 *${task}*\n\n_${quote.trim()}_\n\n*JITO YW CC Greetings Team 💐*`;

    return formattedMessage;
  }, [birthdays, quote, task]);

  const handleChangeQuote = () => {
    fetchQuote();
  };

  const handleChangeTask = () => {
    fetchRandomTask();
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(formatMessage());
  };

  return (
    <div className="relative flex flex-col min-h-screen font-sans">
      <Navbar />
      
      {/* Main content with responsive margin */}
  <div className="flex flex-col flex-grow p-4 mt-24 md:mt-16"> {/* mt-24 for mobile, mt-16 for desktop */}
    <div className="flex flex-col lg:flex-row lg:gap-4">
      {/* Left panel */}
      <div className="flex-1 p-4 mb-4 border rounded shadow lg:mb-0">
        <h1 className="mb-4 text-2xl font-bold">JCCF Birthdays</h1>
        <pre className="p-4 overflow-x-auto whitespace-pre-wrap bg-gray-100 rounded-md">
          {formatMessage()}
        </pre>

        <div className="flex flex-col gap-3 mt-4 sm:flex-row">
          <button
            className="px-4 py-2 text-white transition-colors bg-blue-500 rounded hover:bg-blue-600"
            onClick={handleChangeQuote}
          >
            Change Quote
          </button>

          <button
            className="px-4 py-2 text-white transition-colors bg-green-500 rounded hover:bg-green-600"
            onClick={handleCopyToClipboard}
          >
            Copy to Clipboard
          </button>

          <button
            className="px-4 py-2 text-white transition-colors bg-purple-500 rounded hover:bg-purple-600"
            onClick={handleChangeTask}
          >
            Randomize Task
          </button>
        </div>
      </div>

      {/* Right panel */}
      <div className="w-full p-4 border rounded shadow lg:w-1/3 bg-gray-50">
        <h2 className="mb-4 text-xl font-bold">Today's Birthdays</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="p-2 font-medium text-left text-gray-900">Name</th>
                <th className="p-2 font-medium text-left text-gray-900">Phone</th>
              </tr>
            </thead>
            <tbody>
              {birthdays.length > 0 ? (
                birthdays.map((person) => (
                  <tr key={person.sno} className="border-t">
                    <td className="p-2">{person.name}</td>
                    <td className="p-2">{person.phone}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="p-2" colSpan="2">No birthdays today.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
  );
};

export default BirthdayMessageGenerator;
