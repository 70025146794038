import React from 'react';

function Navbar() {
  return (
    <nav className="fixed top-0 left-0 z-50 w-full p-4 font-bold text-white bg-blue-500 shadow-md">
      <div className="container flex items-center justify-between mx-auto">
        {/* Left-aligned title */}
        <h1 className="flex-1 text-base text-center sm:text-lg md:text-xl lg:text-2xl sm:text-left">
          Birthday Message Generator - JCCF
        </h1>
        {/* Right-aligned name */}
        <p className="flex-1 text-xs text-center sm:text-sm md:text-base lg:text-lg sm:text-right">
          Yash Phulfagar Jain
        </p>
      </div>
    </nav>
  );
}

export default Navbar;
