import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';  // Import Navbar component

function HomePage() {
  return (
    <div className="flex flex-col items-center justify-center h-screen px-4 pt-20 bg-gray-50 sm:px-6 lg:px-8 font-inter">
      <Navbar />  {/* Navbar stays fixed at the top */}

      {/* Main Content */}
      <div className="flex flex-col items-center justify-center flex-grow">
        <h1 className="mb-4 text-4xl font-bold text-center text-gray-800 sm:text-5xl md:text-6xl">
          Welcome to <br /> Auto Whatsapp Message Generator
        </h1>
        <p className="mb-6 text-lg text-center text-gray-600 sm:text-xl md:text-2xl">
        Generates a simple, copy-paste birthday message that you can instantly share on WhatsApp—no more searching for birthdays on the same day or any hassle!
        </p>
        <p className="mb-12 text-lg text-center text-gray-600 sm:text-xl md:text-2xl">
          Developed by Yash Jain <Link to="/about-me" className="text-blue-600 hover:underline">(Who is he?)</Link>
        </p>
        <Link to="/message">
          <button className="px-8 py-3 font-semibold text-white transition duration-300 ease-in-out bg-blue-600 border border-blue-700 rounded-lg shadow-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
            Generate Birthday Message
          </button>
        </Link>
      </div>
    </div>
  );
}

export default HomePage;
